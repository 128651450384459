<template>
  <VForm v-model="modelFormData" ref="Form">
    <div class="flex gap-m mb-20">
      <InfoTextBlock
        labelText="№ позиции"
        :text="modelFormData.sort ? modelFormData.sort : '-'"
      />

      <VInput
        label="Кол-во:"
        class="input--row input-text"
        name="value"
        width="initial"
        input-width="65px"
        :disabled="isDisabled"
        required
        :pattern="pattern.num"
        :custom-validation="validationPositionValue"
      >
        <Validation for="required"/>
        <Validation for="pattern" key-text="num"/>
        <Validation for="customValidation">Значение не может быть меньше количества пружин в партиях</Validation>
      </VInput>

      <InfoTextBlock
        labelText="Масса позиции"
        :text="weightPosition"
      />

      <InfoTextBlock
        labelText="Общий вес"
        :text="totalDirtyWeightConsignments"
      />
    </div>

    <VInput
      label="Артикул:"
      class="input--row input-text"
      name="article"
      input-width="450px"
      :disabled="isDisabled || (!(positionConsignmentsLength === 0) && positionConsignmentsCount)"
      required
    >
      <Validation for="required"/>
    </VInput>

    <p
      class="order-position__stock mb-20"
      :style="countMaterialByArticle ? '' : 'color: #898989'"
    >
      В наличии: <span>{{ countMaterialByArticle || 0 }}</span>
    </p>

    <VInputFiles
      class="mb-30"
      name='files'
      :is-edit="!isDisabled"
      @onRemoveFile="SET_CURRENT_POSITION_FILES_DELETE"
    />

    <VSelect
      label="Диспетчер производства"
      name="responsible"
      class="mb-30"
      width="350px"
      :options="productionMasterList"
      :disabled="isDisabled"
      required
    >
      <Validation for="required"/>
    </VSelect>

    <div class="position">
      <VInput
        name="name"
        label="Наименование"
        required
        :disabled="isDisabled"
      >
        <Validation for="required"/>
      </VInput>
    </div>
  </VForm>
</template>

<script>
import VForm from '@/components/Form/VForm.vue'
import InfoTextBlock from '@/components/ui/AppInfoTextBlock'
import Validation from '@/components/Form/Validation'
import VInput from '@/components/Form/Vinput/VInput'
import VInputFiles from '@/components/Form/VInputFiles/VInputFiles'
import VSelect from '@/components/Form/VSelect'

import { mapGetters, mapMutations } from 'vuex'
import { formulaTotalWeight } from '@/utils/production/position/formulas'
import { fixedNumberCopy } from '@/utils/number'
import { PATTERN_LIST } from '@/const/validation'

export default {
  name: 'PositionHeadForm',
  components: {
    VForm,
    InfoTextBlock,
    Validation,
    VInput,
    VInputFiles,
    VSelect
  },
  props: {
    isDisabled: {
      type: Boolean
    }
  },
  data: () => ({
    pattern: PATTERN_LIST
  }),

  computed: {
    ...mapGetters('orderDetail', {
      currentPosition: 'currentPosition',
      totalDirtyWeightConsignments: 'totalDirtyWeightConsignments',
      positionConsignmentsLength: 'positionConsignmentsLength',
      positionConsignments: 'positionConsignments'
    }),
    ...mapGetters({
      productionMasterList: 'users/productionMasterList',
      countMaterialByArticle: 'warehouse/countMaterialByArticle'
    }),

    modelFormData: {
      get () {
        return this.currentPosition
      },
      set (newFormData) {
        this.SET_CURRENT_POSITION_VALUE({ value: newFormData })
      }
    },

    positionConsignmentsCount () {
        let isValue = false
        this.positionConsignments.forEach(item => {
            if (item.value !== 0) isValue = true
        })
        return isValue
    },

    calcTotalWeight () {
      return formulaTotalWeight(this.modelFormData)
    },

    weightPosition () {
      let weightTotal = this.calcTotalWeight ? this.calcTotalWeight * this.modelFormData.value : 0
      if (weightTotal !== 0) {
        weightTotal = fixedNumberCopy(weightTotal, 2)
      }
      return weightTotal
    }
  },

  methods: {
    ...mapMutations('orderDetail', [
      'SET_CURRENT_POSITION_VALUE',
      'SET_CURRENT_POSITION_FILES_DELETE'
    ]),

    validationPositionValue (value) {
      const consignments = this.modelFormData.consignments
      if (!consignments?.length) return true

      const consignmentsValue = consignments.reduce((prev, current) => {
        return prev + current.value
      }, 0)

      return +value >= consignmentsValue
    }
  }
}
</script>
