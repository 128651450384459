<template>
  <div>
    <VButton
      v-if="permissionCreatePositionTemplate"
      text="Создать шаблон"
      icon="create"
      size="sm"
      @click.native="openModal"
    />

    <ModalConfirmation
      v-if="isModal"
      title="Вы действительно хотите создать шаблон из данной позиции?"
      @confirm="createTemplate"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { TemplatePositionApiV2 } from '@/api/production/Template/TemplatePositionApi'
import { mapGetters } from 'vuex'

export default {
  components: { ModalConfirmation, VButton },
  mixins: [mixinModal],
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('permission', {
      permissionCreatePositionTemplate: 'permissionCreatePositionTemplate'
    })
  },
  methods: {
    async createTemplate () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')
        const params = {
            position_id: this.position.id
        }
        await TemplatePositionApiV2.create(params)
        // await this.$store.dispatch('productions/template/position/postFiles', { id: response.data.id, files: this.position.files })
        showAlertSuccess('Шаблон создан')
        this.closeModal()
        this.refreshTemplates()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    refreshTemplates () {
      this.$store.dispatch('productions/template/position/loadAll')
    }
  }
}
</script>
