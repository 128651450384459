<template>
  <div class="dropdown-menu">

    <div class="dropdown-menu__dots" @click="toggleDropdownMenu">
      <span></span>
    </div>

    <transition name="fade">
      <div class="dropdown-menu__items" v-if="isOpen">
        <slot name="dropdownItems" />
      </div>
    </transition>

  </div>

</template>

<script>

export default {
  name: 'DropdownMenu',
  data () {
    return {
      isOpen: false
    }
  },

  methods: {
    toggleDropdownMenu () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped lang="scss">

</style>
