<template>
  <div class="stage-implement">
      <div class="stage-implement__header">
        <h3>Этапы выполнения</h3>

        <div class="d-flex ai-c gap-l">
          <div class="d-flex ai-c gap-xxs">
            <svgicon
              class="svg-icon--status-start"
              icon="status-start"
              width="23"
              height="24"
            />

            <span>Ожидает начала:&nbsp;</span>
            <span>{{consignmentsCount.waiting_start}}&nbsp;шт</span>
          </div>

          <div class="d-flex ai-c gap-xxs">
            <svgicon
              class="svg-icon--status-ready"
              icon="status-ready"
              width="27"
              height="26"
            />

            <span>Склад:&nbsp;</span>
            <span>{{consignmentsCount.from_warehouse}}&nbsp;шт</span>
          </div>

          <div class="d-flex ai-c gap-xxs">
            <svgicon
              class="svg-icon--status-shipped"
              icon="status-shipped"
              width="32"
              height="21"
            />

            <span>Отгружено:&nbsp;</span>
            <span>{{consignmentsCount.shipped}}&nbsp;шт</span>
          </div>
        </div>
      </div>

      <div class="stage-implement__body">
        <div
          class="stage-implement__column-wrap"
          v-for="(stage, i) in stageStore"
          :key="i + 'stage'"
        >
          <p class="stage-implement__column-title">{{ i+1 }}&nbsp;Этап</p>
          <div class="stage-implement__column">
            <div class="stage-implement__column-header">
              <p>{{ stage.name || 'Этап' }}</p>
              <p>{{ stage.listOfConsignment && stage.listOfConsignment.length }}</p>
            </div>

            <div v-if="stage.listOfConsignment" class="stage-implement__column-body">
              <StageLineImplementCard
                v-for="(consignment, i) in stage.listOfConsignment"
                :key="i + 'consignment'"
                :stageImplement="consignment"
              />
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import StageLineImplementCard from '@/components/views/Production/OrderDetail/StageLineImplementCard'
import { mapGetters } from 'vuex'
export default {
  name: 'StageLineImplementation',
  components: { StageLineImplementCard },

  computed: {
    ...mapGetters('orderDetail', {
      stageStore: 'stageStore',
      currentPosition: 'currentPositionActive'
    }),
    consignmentsCount () {
      return {
        waiting_start: this.currentPosition?.waiting_start || 0,
        from_warehouse: this.currentPosition?.from_warehouse || 0,
        shipped: this.currentPosition?.shipped || 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.stage-implement {
  background: #FFFFFF;
  border: 1px solid $color__gray-blue;
  box-shadow: $color__card-box-shadow;
  border-radius: 20px;

  &__header {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color__gray-blue;
    padding: 20px 30px;
  }

  &__flex {
    display: flex;
    align-items: center;
  }

  &__body {
    padding: 40px 60px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__column {
    border: 1px solid $color__gray-blue;
    border-radius: 20px;
    padding: 16px 16px 20px;

    &-wrap {
      display: inline-block;
      min-width: 215px;
      max-width: 215px;
    }

    &-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: $color__cyan;
      text-align: center;
      margin-bottom: 8px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      p:first-child {
        font-weight: 500;
        font-size: 16px;
        color: $color__orange-dawn;
      }
      p:last-child {
        font-weight: 600;
        font-size: 14px;
        color: var(--color__pearl-dark-gray);
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
  }
}
</style>
