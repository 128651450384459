<template>
  <ModalConfirmation
    title="Вы действительно хотите передать партию на следующий этап?"
    text-btn-reject="Отмена"
    @confirm="stageNextHandler"
    @reject="onReject"
  >
    <VSelect
      v-if="stageList.length"
      v-model="formData"
      label="Отправить на этап"
      class="mb-30"
      name="stageNext"
      :options="stageList"
    />
  </ModalConfirmation>
</template>

<script>
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import VSelect from '@/components/Form/VSelect'
import ConsignmentController from '@/controllers/production/Consignment/ConsignmentController'

export default {
  name: 'ModalConsignmentStageNext',
  components: {
    ModalConfirmation,
    VSelect
  },
  props: {
    consignment: {
      type: Object,
      required: true
    },
    stageLine: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    formData: {}
  }),
  computed: {
    stageList () {
      if (!this.stageLine?.length) return []

      const indexCurrentStage = this.stageLine.findIndex(stage => stage.status.id === this.consignment.status.id)

      return this.stageLine
        .slice(indexCurrentStage + 1, -3)
        .map(stage => ({
          id: stage.status.id,
          name: stage.status.name
        }))
    }
  },
  methods: {
    async stageNextHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const data = {
          changer_id: this.$store.getters.currentUserId,
          status_id: this.formData?.id || null
        }

        const response = await ConsignmentController.goStageNext(this.consignment.id, data)
        if (response?.status !== 200) return

        this.$emit('goStageNext', response.data)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
